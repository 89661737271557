body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* [data-amplify-authenticator]:before {
  content: ' ';
  display: block;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-image: url('./images/marmon.jpeg');
  background-position: top;
  background-size: cover;
  opacity: .4; 
  z-index: -1;
} */

@font-face {
  font-family: 'Roboto-Regular';
  src: local('Roboto-Regular'), url('fonts/Roboto/Roboto-Regular.ttf');
}

@font-face {
  font-family: 'Agbalumo-Regular';
  src: local('Agbalumo-Regular'), url('fonts/Agbalumo/Agbalumo-Regular.ttf');
}

@font-face {
  font-family: 'Montserrat-Light';
  src: local('Montserrat-Light'), url('fonts/Montserrat/static/Montserrat-Light.ttf');
}

@font-face {
  font-family: 'Montserrat-Medium';
  src: local('Montserrat-Medium'), url('fonts/Montserrat/static/Montserrat-Medium.ttf');
}

.raw-textare {
    min-width: 100%;
    height: 6.5rem;
    padding-bottom: 0.5rem;
    padding-top: 0.5rem;
    border: 1px solid rgb(201, 206, 214);
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    line-height: 24px;
    font-size: 14px;
    transition: all 0.4s ease-in-out 0s;
    font-weight: 500;
    border-radius: 6px;
    background: rgb(255, 255, 255);
    width: 100%;
    box-sizing: border-box;
}